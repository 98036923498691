import { dev } from '$app/environment';
import * as Sentry from "@sentry/sveltekit";

Sentry.init({
    dsn: "https://2cccda060d3931bc0b822c9f2ee405cd@o499198.ingest.us.sentry.io/4508265068429312",
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,

    // Optional: Initialize Session Replay:
    integrations: [Sentry.replayIntegration()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    enabled: !dev,
});


export const handleError = Sentry.handleErrorWithSentry();
